import "./Home.css";
import React from "react";
import { useNavigate } from "react-router-dom";

const Home = ({ setAuthenticated }) => {
    const navigate = useNavigate();

    const handleIdMeLogin = () => {
        const clientId = '440dbe5904d383e92c8c5cec1395a984';
        const redirectUri = 'http://localhost:5000/auth/idme/callback';
        const authUrl = `https://api.id.me/oauth/authorize?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=code&scope=http://idmanagement.gov/ns/assurance/ial/2/aal/2`;
        window.location.href = authUrl;
    };

    const handleGuestLogin = () => {
        setAuthenticated(false);
        navigate("/form");
    };

    return (
        <div className="Home">
            <h1>Helene Incident Form</h1>
            <p>To help ensure that resources are directed to those who need them most, we encourage you to verify your identity through our trusted partner, ID.me. While we will still accept unverified submissions, verified reports receive higher priority to minimize misuse and avoid wasting our limited resources. This allows our volunteers, first responders, and search and rescue teams to focus on genuine needs. Verifying your identity supports our mission to provide timely assistance to those truly in need. Please take a moment to verify your identity—especially if you’re submitting this on behalf of someone who urgently needs help.</p>
            <div className="button-group">
                <button className="auth-button" onClick={handleIdMeLogin}></button>
                <button className="guest-button" onClick={handleGuestLogin}>Continue without Verifying</button>
            </div>
        </div>
    );
};

export default Home;