import React, { useState, useEffect } from 'react';
import './Basics.css';

const Basics = ({ nextStep, updateFormData, formData }) => {
    const [localData, setLocalData] = useState({
        firstName: formData.firstName || '',
        lastName: formData.lastName || '',
        email: formData.email || '',
        phone: formData.phone || '',
        affectedPeople: formData.affectedPeople || '',
        incidentType: formData.incidentType || "",
        incidentDescription: formData.incidentDescription || "",
        images: formData.images || [],
        imagePreviews: formData.imagePreviews || [], // To store image preview URLs
        proximityToHazard: formData.proximityToHazard || "",
        communicationMethod: formData.communicationMethod || "",
        medicalSuppliesNeeded: formData.medicalSuppliesNeeded || false,
        petsNeedHelp: formData.petsNeedHelp || false,
        additionalRequests: formData.additionalRequests || ""
    });

    // Handle input changes locally
    const handleChange = (e) => {
        const { name, value } = e.target;
        setLocalData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    // Handle form submission
    const handleSubmit = (e) => {
        e.preventDefault();
        updateFormData(localData, 'basics');  // Pass localData back to App's state
        nextStep();  // Proceed to the next step
    };

    useEffect(() => {
        setLocalData(formData || {
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            affectedPeople: '',
            incidentType: formData.incidentType || "",
            incidentDescription: formData.incidentDescription || "",
            images: formData.images || [],
            imagePreviews: formData.imagePreviews || [], // To store image preview URLs
            proximityToHazard: formData.proximityToHazard || "",
            communicationMethod: formData.communicationMethod || "",
            medicalSuppliesNeeded: formData.medicalSuppliesNeeded || false,
            petsNeedHelp: formData.petsNeedHelp || false,
            additionalRequests: formData.additionalRequests || ""
        });
    }, [formData]);

    return (
        <div className="Basics">
            <h1>Basics</h1>
            <form onSubmit={handleSubmit}>
                {/* First and Last Name in the same row */}
                <div className="name-row">
                    <div className="form-group">
                        <label>
                            First Name:
                            <input
                                type="text"
                                name="firstName"
                                value={localData.firstName}
                                onChange={handleChange}
                                required
                            />
                        </label>
                    </div>
                    <div className="form-group">
                        <label>
                            Last Name:
                            <input
                                type="text"
                                name="lastName"
                                value={localData.lastName}
                                onChange={handleChange}
                                required
                            />
                        </label>
                    </div>
                </div>

                {/* Email Address */}
                <div className="form-group">
                    <label>
                        Email Address:
                        <input
                            type="email"
                            name="email"
                            value={localData.email}
                            onChange={handleChange}
                            required
                        />
                    </label>
                </div>

                {/* Phone Number */}
                <div className="form-group">
                    <label>
                        Phone Number:
                        <input
                            type="tel"
                            name="phone"
                            value={localData.phone}
                            onChange={handleChange}
                            required
                        />
                    </label>
                </div>

                {/* Who is affected dropdown */}
                <div className="form-group">
                    <label>
                        Who Is Affected?:
                        <select
                            name="affectedPeople"
                            value={localData.affectedPeople}
                            onChange={handleChange}
                            required
                        >
                            <option value="">--Select an Item--</option>
                            <option value="Myself">Myself</option>
                            <option value="Family Member">Family Member</option>
                            <option value="Someone Else">Someone Else</option>
                            <option value="Multiple People">Multiple People</option>
                        </select>
                    </label>
                </div>

                <div>
                    <button type="submit">Next</button>
                </div>
            </form>
        </div>
    );
}

export default Basics;