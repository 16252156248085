import "./Details.css";
import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera, faUpload } from '@fortawesome/free-solid-svg-icons';


const Details = ({ nextStep, prevStep, updateFormData, formData }) => {
    const [localData, setLocalData] = useState(formData || {
        incidentType: formData.incidentType || "",
        incidentDescription: formData.incidentDescription || "",
        images: formData.images || [],
        imagePreviews: formData.imagePreviews || [], // To store image preview URLs
        proximityToHazard: formData.proximityToHazard || "",
        communicationMethod: formData.communicationMethod || "",
        medicalSuppliesNeeded: formData.medicalSuppliesNeeded || false,
        petsNeedHelp: formData.petsNeedHelp || false,
        additionalRequests: formData.additionalRequests || ""
    });

    const incidentTypes = [
        "Immediate (life-threatening)",
        "Emergency (could be life-threatening)",
        "Urgent (not life-threatening)",
        "Semi-urgent (not life-threatening)",
        "Non-urgent (needs treatment as time permits)",
        "Other"
    ];

    const hazards = [
        "Near Flood Zone",
        "Near Chemical Storage Facility",
        "Near Downed Power Lines",
        "Near Gas Pipeline",
        "None of the Above"
    ];

    const communicationMethods = [
        "Phone Service Available",
        "Internet Access Available",
        "Using Someone Else's Device",
        "No Communication Available"
    ];

    // Handle input changes
    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setLocalData((prevData) => ({
            ...prevData,
            [name]: type === "checkbox" ? checked : value
        }));
    };

    // Handle file uploads
    const handleFileChange = (e) => {
        const newFiles = Array.from(e.target.files);
        const newPreviews = newFiles.map(file => URL.createObjectURL(file));

        setLocalData((prevData) => ({
            ...prevData,
            images: [...(prevData.images || []), ...newFiles],
            imagePreviews: [...(prevData.imagePreviews || []), ...newPreviews] // Store new previews
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        updateFormData(localData, 'details');  // Pass local data to parent
        nextStep();  // Proceed to the next step or finalize submission
    };

    useEffect(() => {
        // Sync local state with parent formData when formData changes
        setLocalData(formData || {
            incidentType: formData.incidentType || "",
            incidentDescription: formData.incidentDescription || "",
            images: formData.images || [],
            imagePreviews: formData.imagePreviews || [], // To store image preview URLs
            proximityToHazard: formData.proximityToHazard || "",
            communicationMethod: formData.communicationMethod || "",
            medicalSuppliesNeeded: formData.medicalSuppliesNeeded || false,
            petsNeedHelp: formData.petsNeedHelp || false,
            additionalRequests: formData.additionalRequests || ""
        });
    }, [formData]);

    return (
        <div className="Details">
            <h1>Assistance Request Details</h1>
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label>
                        <span>Incident Type:</span>
                        <select
                            name="incidentType"
                            value={localData.incidentType}
                            onChange={handleChange}
                            required
                        >
                            <option value="">--Select Incident Type--</option>
                            {incidentTypes.map((type, index) => (
                                <option key={index} value={type}>
                                    {type}
                                </option>
                            ))}
                        </select>
                    </label>
                </div>

                {/* Incident Description */}
                <div className="form-group">
                    <label>
                        <span>Describe What Happened:</span>
                        <textarea
                            name="incidentDescription"
                            value={localData.incidentDescription}
                            onChange={handleChange}
                            rows="5"
                            placeholder="Provide details about the incident..."
                            required
                        />
                    </label>
                </div>

                {/* Image Upload and Take Picture in the same row */}
                <div className="form-group image-buttons">
                    <div className="image-buttons-container">
                        <label className="upload-button">
                            <FontAwesomeIcon icon={faUpload} className="icon" />
                            <p>Upload Pictures</p>
                            <input
                                type="file"
                                name="images"
                                accept="image/*"
                                multiple
                                onChange={handleFileChange}
                            />
                        </label>

                        <label className="camera-button">
                            <FontAwesomeIcon icon={faCamera} className="icon" />
                            <p>Take Pictures</p>
                            <input
                                type="file"
                                name="images"
                                accept="image/*;capture=camera"
                                capture="environment"
                                onChange={handleFileChange}
                            />
                        </label>
                    </div>

                    <div className="uploaded-files">
                        {localData.imagePreviews ? (
                            localData.imagePreviews.map((src, index) => (
                                <img key={index} src={src} alt={`preview-${index}`} className="image-preview" />
                            ))
                        ) : (
                            <span>No files uploaded</span>
                        )}
                    </div>
                </div>

                {/* Proximity to Hazard */}
                <div className="form-group">
                    <label>
                        <span>Proximity to Known Hazard:</span>
                        <select
                            name="proximityToHazard"
                            value={localData.proximityToHazard}
                            onChange={handleChange}
                            required
                        >
                            <option value="">-- Select a Hazard --</option>
                            {hazards.map((hazard, index) => (
                                <option key={index} value={hazard}>
                                    {hazard}
                                </option>
                            ))}
                        </select>
                    </label>
                </div>

                {/* Communication Method */}
                <div className="form-group">
                    <label>
                        <span>Available Communication Methods:</span>
                        <select
                            name="communicationMethod"
                            value={localData.communicationMethod}
                            onChange={handleChange}
                            required
                        >
                            <option value="">-- Select Communication Method --</option>
                            {communicationMethods.map((method, index) => (
                                <option key={index} value={method}>
                                    {method}
                                </option>
                            ))}
                        </select>
                    </label>
                </div>

                {/* Custom-styled Checkboxes (same row) */}
                <div className="form-group checkbox-group">
                    <label className="custom-checkbox">
                        <input
                            type="checkbox"
                            name="medicalSuppliesNeeded"
                            checked={localData.medicalSuppliesNeeded}
                            onChange={handleChange}
                        />
                        <span className="checkmark"></span>
                        Medical Supplies Needed
                    </label>

                    <label className="custom-checkbox">
                        <input
                            type="checkbox"
                            name="petsNeedHelp"
                            checked={localData.petsNeedHelp}
                            onChange={handleChange}
                        />
                        <span className="checkmark"></span>
                        Pets or Livestock Need Help
                    </label>
                </div>

                {/* Additional Information */}
                <div className="form-group">
                    <label>
                        <span>Additional Requests or Information:</span>
                        <textarea
                            name="additionalRequests"
                            value={localData.additionalRequests}
                            onChange={handleChange}
                            rows="4"
                            placeholder="Provide any extra details or requests..."
                        />
                    </label>
                </div>

                <div className="button-row">
                    <button onClick={prevStep}>Back</button>
                    <button type="submit">Next</button>
                </div>
            </form>
        </div>
    );
};

export default Details;