import "./Location.css";
import React, { useState, useEffect, useRef } from "react";
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

// Fix for missing default marker icon issue in React-Leaflet
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

const Location = ({ nextStep, prevStep, updateFormData, formData }) => {
    const [useCurrentLocation, setUseCurrentLocation] = useState(null);
    const [locationGranted, setLocationGranted] = useState(null);
    const [coordinates, setCoordinates] = useState(formData.coordinates || {
        lat: null,
        lng: null,
    });
    const [loading, setLoading] = useState(false);
    const [address, setAddress] = useState(formData.address || {
        street: '',
        city: '',
        state: '',
        zip: '',
        country: ''
    });

    const markerRef = useRef(null);

    useEffect(() => {
        if (useCurrentLocation) {
            setLoading(true);
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        setLocationGranted(true);
                        const newCoordinates = {
                            lat: position.coords.latitude,
                            lng: position.coords.longitude,
                        };
                        console.log("Setting coordinates: ", newCoordinates);
                        setCoordinates(newCoordinates);
                        setLoading(false);
                    },
                    (error) => {
                        setLocationGranted(false);
                        setLoading(false);
                        console.error("Geolocation error:", error);
                    }
                );
            } else {
                setLocationGranted(false);
                setLoading(false);
                console.error("Geolocation not supported by this browser.");
            }
        }
    }, [useCurrentLocation]);

    const handleAddressChange = (e) => {
        const { name, value } = e.target;
        setAddress(prevAddress => ({
            ...prevAddress,
            [name]: value
        }));
    };

    const handleAddressSubmit = (e) => {
        e.preventDefault();
        // Submit the manual address to the parent
        updateFormData({ address }, 'location');
        nextStep();
    };

    const handleConfirmLocation = () => {
        // Empty the address object if it exists
        if (formData.address) {
            updateFormData({ address: {} }, 'location');
        }

        // Pass coordinates to the parent
        updateFormData({ coordinates }, 'location');

        nextStep();
    };

    return (
        <div className="Location">
            <h1>Location</h1>

            {useCurrentLocation === null ? (
                <div className="location-choice">
                    <p>Would you like to use your current location?</p>
                    <div className="button-row">
                        <button onClick={() => setUseCurrentLocation(true)}>Yes, use my location</button>
                        <button onClick={() => setUseCurrentLocation(false)}>No, I will enter an address</button>
                    </div>
                </div>
            ) : loading ? (
                <div className="loading-spinner">
                    <div className="spinner"></div>
                    <p>Requesting location...</p>
                </div>
            ) : useCurrentLocation && locationGranted && coordinates.lat && coordinates.lng ? (
                <div className="map-outer">
                    <div className="map-container">
                        <MapContainer
                            center={[coordinates.lat, coordinates.lng]}
                            zoom={13}
                            style={{ height: "400px", width: "100%" }}
                            zoomControl={false}
                        >
                            <TileLayer
                                maxZoom={19}
                                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />
                            <Marker
                                position={[coordinates.lat, coordinates.lng]}
                                draggable={true}
                                ref={markerRef}
                                eventHandlers={{
                                    dragend: () => {
                                        const marker = markerRef.current;
                                        if (marker != null) {
                                            const newPosition = marker.getLatLng();
                                            setCoordinates({
                                                lat: newPosition.lat,
                                                lng: newPosition.lng
                                            });
                                        }
                                    },
                                }}
                            >
                                <Popup>
                                    Your current location. Drag the marker if this is incorrect.
                                </Popup>
                            </Marker>

                        </MapContainer>
                    </div>
                    <div className="button-row">
                        <button type="button" onClick={prevStep}>Back</button>
                        <button type="button" onClick={handleConfirmLocation}>Confirm</button>
                    </div>
                </div>
            ) : useCurrentLocation === false || !locationGranted ? (
                <div className="address-form">
                    <p>Please enter your address:</p>
                    <form onSubmit={handleAddressSubmit}>
                        <div className="form-group">
                            <label>
                                <span>Street Address:</span>
                                <input
                                    type="text"
                                    name="street"
                                    value={address.street}
                                    onChange={handleAddressChange}
                                    required
                                />
                            </label>
                        </div>

                        <div className="form-group">
                            <label>
                                <span>City:</span>
                                <input
                                    type="text"
                                    name="city"
                                    value={address.city}
                                    onChange={handleAddressChange}
                                    required
                                />
                            </label>
                        </div>

                        <div className="form-group">
                            <label>
                                <span>State/Province:</span>
                                <input
                                    type="text"
                                    name="state"
                                    value={address.state}
                                    onChange={handleAddressChange}
                                    required
                                />
                            </label>
                        </div>

                        <div className="form-group">
                            <label>
                                <span>ZIP/Postal Code:</span>
                                <input
                                    type="text"
                                    name="zip"
                                    value={address.zip}
                                    onChange={handleAddressChange}
                                    required
                                />
                            </label>
                        </div>

                        <div className="form-group">
                            <label>
                                <span>Country:</span>
                                <input
                                    type="text"
                                    name="country"
                                    value={address.country}
                                    onChange={handleAddressChange}
                                    required
                                />
                            </label>
                        </div>

                        <div className="button-row">
                            <button type="button" onClick={prevStep}>Back</button>
                            <button type="submit">Next</button>
                        </div>
                    </form>
                </div>
            ) : null}
        </div>
    );
};

export default Location;
