import React from 'react';
import './PageIndicator.css';

const PageIndicator = ({ currentStep, setStep, visible }) => {
    const steps = [1, 2, 3, 4];

    if (!visible) {
        return null;
    }

    return (
        <div className="page-indicator">
            {steps.map((step) => (
                <div
                    key={step}
                    className={`circle ${currentStep === step ? 'active' : ''}`}
                    onClick={() => setStep(step)}
                ></div>
            ))}
        </div>
    );
};

export default PageIndicator;