import "./Final.css";
import React from "react";

const Final = () => {
    return (
        <div className="Final">
            <h1>Final Submission</h1>
            <p>Thank you for your submission!</p>
            <p>
                Your submission has been received and is being processed. You will receive an email confirmation shortly.
            </p>
        </div>
    );
}

export default Final;