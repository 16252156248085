import React, { useEffect, useState } from "react";
import axios from "axios";
import "./Review.css";

const Review = ({ formData, nextStep, prevStep, updateFormData, verified }) => {
    const [isFormValid, setIsFormValid] = useState(false);
    const [agreedLegal, setAgreedLegal] = useState(false);
    const [agreedPrivacy, setAgreedPrivacy] = useState(false);
    const [agreedDataSharing, setAgreedDataSharing] = useState(false);
    const [agreedLocalDataSharing, setLocalDataSharing] = useState(true);
    const [agreedStateDataSharing, setStateDataSharing] = useState(true);
    const [agreedFederalDataSharing, setFederalDataSharing] = useState(true);
    const [agreedPrivateNonProfitsNGOsChurchesDataSharing, setPrivateNonProfitsNGOsChurchesDataSharing] = useState(false);
    const [agreedNeighborsPrivateCitizensDataSharing, setNeighborsPrivateCitizensDataSharing] = useState(false);


    // useEffect to validate the form on every render
    useEffect(() => {
        // Helper function to check if all required fields are filled
        const validateForm = () => {
            const { basics, location, details } = formData;

            // Check if required fields in 'basics' are filled
            if (
                !basics.firstName ||
                !basics.lastName ||
                !basics.email ||
                !basics.phone
            ) {
                return false;
            }

            // Check if location (either address or coordinates) is provided
            if (!location.address.street && !location.coordinates.lat) {
                return false;
            }

            // Check if required fields in 'details' are filled
            if (
                !details.incidentType ||
                !details.incidentDescription ||
                !details.proximityToHazard ||
                !details.communicationMethod
            ) {
                return false;
            }

            // All required fields are valid
            return true;
        };

        setIsFormValid(validateForm());
    }, [formData]);

    // Function to build message that will be shown on "All Chat Rooms" in TAK clients and
    // also displayed in remarks at the lat, lng specified by affected user
    const buildMessageToServer = (data) => {
      const now = new Date();
      const currentTime = now.toISOString();
      const { basics, location, details } = data;
      const { firstName, lastName, email, phone, affectedPeople } = basics;
      const { address, coordinates } = location;
      const { lat, lng } = coordinates;
      const { street, city, state, zip, country } = address;
      //const { description, images, incidentType } = incident;
      const { incidentType, incidentDescription, images, proximityToHazard, communicationMethod, medicalSuppliesNeeded, petsNeedHelp, additionalRequests } = details;
    
    
      let shareData = 'Share Data With: ';
      if (agreedLocalDataSharing) {
        shareData = shareData + 'Local Authorities (City/County/Township), ';
      }
      if (agreedStateDataSharing) {
        shareData = shareData + 'State Authorities, ';
      }
      if (agreedFederalDataSharing) {
        shareData = shareData + 'Federal Authorities, ';
      }
      if (agreedPrivateNonProfitsNGOsChurchesDataSharing) {
        shareData = shareData + 'Private Entities (Non-Profits, NGOs, Churches), ';
      }
      if (agreedNeighborsPrivateCitizensDataSharing) {
        shareData = shareData + 'Neighbors or Private Citizens, ';
      }
      if (!agreedLocalDataSharing && !agreedStateDataSharing && !agreedFederalDataSharing && !agreedPrivateNonProfitsNGOsChurchesDataSharing && !agreedNeighborsPrivateCitizensDataSharing) {
        shareData = shareData + 'Nobody';
      } else {
        shareData = shareData.slice(0, -2);
      }
      const message = `Emergency Request Received at ${currentTime} from ${firstName}, ${lastName} (phone: ${phone}, email: ${email}): Coordinates: [${lat}, ${lng}]; Address: {${street}, ${city}, ${state}, ${zip}, ${country}}; ` +
        `Affected People: ${affectedPeople}; ` +
        `Incident Type: ${incidentType}; Description: ${incidentDescription}; Additional Requests: ${additionalRequests}; ` +
        `Proximity To Hazard: ${proximityToHazard}; Communication Method: ${communicationMethod}; Medical Supplies Needed: ${medicalSuppliesNeeded}; Pets Need Help: ${petsNeedHelp}; ${shareData}`;
    
      console.log("Message to be sent to server: ");
      console.log(message);

      return { now, phone, lat, lng, street, city, state, zip, country, message };
    };

    const sendData = async (data) => {
        try {
            const response = await axios.post("/api/send-data", { data });
            console.log("Data sent successfully: ", response.data);
        } catch (error) {
            console.error("Error sending data: ", error);
        }
    };

    // const escapeXml = (unsafe) => {
    //     return unsafe.replace(/[<>&'"]/g, (c) => {
    //         switch (c) {
    //             case "<":
    //                 return "&lt;";
    //             case ">":
    //                 return "&gt;";
    //             case "&":
    //                 return "&amp;";
    //             case "'":
    //                 return "&apos;";
    //             case '"':
    //                 return "&quot;";
    //             default:
    //                 return c;
    //         }
    //     });
    // };

    const handleSubmit = () => {
        if (!isFormValid) return; // Prevent submission if form is invalid

        console.log("Final form data submitted:", formData);
        console.log("agreedLocalDataSharing: ", agreedLocalDataSharing);
        console.log("agreedStateDataSharing: ", agreedStateDataSharing);
        console.log("agreedFederalDataSharing: ", agreedFederalDataSharing);
        console.log("agreedPrivateNonProfitsNGOsChurchesDataSharing: ", agreedPrivateNonProfitsNGOsChurchesDataSharing);
        console.log("agreedNeighborsPrivateCitizensDataSharing: ", agreedNeighborsPrivateCitizensDataSharing);

        const serverData = buildMessageToServer(formData);
        console.log(`Now: ${serverData.now}`);
        console.log(`lat: ${serverData.lat}`);

        sendData(serverData);
        // Placeholder, emptying form
        updateFormData({}, "all");
        nextStep();
    };

    return (
        <div className="Review">
            <h1>Review Your Submission</h1>

            {/* Verification */}
            <div className="verification">
                <h2>Verification</h2>
                <p>
                    <strong>ID.me Verified:</strong> {verified ? "Yes" : "No"}
                </p>
            </div>

            {/* Basics Section */}
            <div className="review-section">
                <h2>Basics</h2>
                <p>
                    <strong>First Name:</strong> {formData.basics.firstName}
                </p>
                <p>
                    <strong>Last Name:</strong> {formData.basics.lastName}
                </p>
                <p>
                    <strong>Email:</strong> {formData.basics.email}
                </p>
                <p>
                    <strong>Phone:</strong> {formData.basics.phone}
                </p>
                <p>
                    <strong>Affected People:</strong> {formData.basics.affectedPeople}
                </p>
            </div>

            {/* Location Section */}
            <div className="review-section">
                <h2>Location</h2>
                {formData.location.coordinates &&
                    formData.location.coordinates.lat &&
                    formData.location.coordinates.lng ? (
                    <p>
                        <strong>Coordinates:</strong> {formData.location.coordinates.lat},{" "}
                        {formData.location.coordinates.lng}
                    </p>
                ) : formData.location.address && formData.location.address.street ? (
                    <p>
                        <strong>Address:</strong> {formData.location.address.street},{" "}
                        {formData.location.address.city}, {formData.location.address.state},{" "}
                        {formData.location.address.zip}, {formData.location.address.country}
                    </p>
                ) : (
                    <p>
                        <strong>Location:</strong> No location provided
                    </p>
                )}
            </div>

            {/* Details Section */}
            <div className="review-section">
                <h2>Details</h2>
                <p>
                    <strong>Incident Type:</strong>{" "}
                    {formData.details.incidentType}
                </p>
                <p>
                    <strong>Incident Description:</strong>{" "}
                    {formData.details.incidentDescription}
                </p>

                <p>
                    <strong>Number of Images Uploaded:</strong>{" "}
                    {formData.details.images.length}
                </p>

                <p>
                    <strong>Proximity to Hazard:</strong>{" "}
                    {formData.details.proximityToHazard}
                </p>
                <p>
                    <strong>Communication Method:</strong>{" "}
                    {formData.details.communicationMethod}
                </p>
                <p>
                    <strong>Medical Supplies Needed:</strong>{" "}
                    {formData.details.medicalSuppliesNeeded ? "Yes" : "No"}
                </p>
                <p>
                    <strong>Pets or Livestock Need Help:</strong>{" "}
                    {formData.details.petsNeedHelp ? "Yes" : "No"}
                </p>
                <p>
                    <strong>Additional Requests:</strong>{" "}
                    {formData.details.additionalRequests || "None"}
                </p>
            </div>

            {/* <div className="review-section"> */}
                <div class="form-section" id="legalDisclaimerSection">
                    <h2>Legal Disclaimer & Privacy Policy</h2>
                    <div className="nice-checkbox">
                        <input
                            type="checkbox"
                            id="privacyPolicyAgreement"
                            name="privacyPolicyAgreement"
                            onChange={() => setAgreedLegal(!agreedLegal)}
                            required
                        />
                        <label for="privacyPolicyAgreement">
                            I agree to the privacy policy and understand that my information
                            will be shared with emergency services and response teams.
                        </label>
                    </div>
                    <div className="nice-checkbox">
                        <input
                            type="checkbox"
                            id="legalDisclaimerAgreement"
                            name="legalDisclaimerAgreement"
                            onChange={() => setAgreedPrivacy(!agreedPrivacy)}
                            required
                        />
                        <label for="legalDisclaimerAgreement">
                            I understand that this service is not a substitute for 911, and I
                            may experience delays in response due to volume or connectivity
                            issues.
                        </label>
                    </div>
                </div>
            {/* </div> */}

            {/* <div className="review-section"> */}
                <div class="form-section" id="dataSharingSection">
                    <h2>Data Sharing Preferences</h2>
                    <div className="nice-checkbox">
                        <input
                            type="checkbox"
                            id="localDataSharing"
                            name="localDataSharing"
                            onChange={() => setLocalDataSharing(!agreedLocalDataSharing)}
                            checked={agreedLocalDataSharing}
                        />
                        <label for="localDataSharing">
                            Share with Local Authorities (City/County/Township)
                        </label>
                    </div>
                    <div className="nice-checkbox">
                        <input
                            type="checkbox"
                            id="stateDataSharing"
                            name="stateDataSharing"
                            onChange={() => setStateDataSharing(!agreedStateDataSharing)}
                            checked={agreedStateDataSharing}
                        />
                        <label for="stateDataSharing">Share with State Authorities</label>
                    </div>
                    <div className="nice-checkbox">
                        <input
                            type="checkbox"
                            id="federalDataSharing"
                            name="federalDataSharing"
                            onChange={() => setFederalDataSharing(!agreedFederalDataSharing)}
                            checked={agreedFederalDataSharing}
                        />
                        <label for="federalDataSharing">
                            Share with Federal Authorities
                        </label>
                    </div>
                    <div className="nice-checkbox">
                        <input
                            type="checkbox"
                            id="privateEntityDataSharing"
                            name="privateEntityDataSharing"
                            onChange={() => setPrivateNonProfitsNGOsChurchesDataSharing(!agreedPrivateNonProfitsNGOsChurchesDataSharing)}
                        />
                        <label for="privateEntityDataSharing">
                            Share with Private Entities (Non-Profits, NGOs, Churches)
                        </label>
                    </div>
                    <div className="nice-checkbox">
                        <input
                            type="checkbox"
                            id="neighborDataSharing"
                            name="neighborDataSharing"
                            onChange={() => setNeighborsPrivateCitizensDataSharing(!agreedNeighborsPrivateCitizensDataSharing)}
                        />
                        <label for="neighborDataSharing">
                            Share with Neighbors or Private Citizens
                        </label>
                    </div>
                    <div className="nice-checkbox">
                        <input
                            type="checkbox"
                            id="dataSharingConsent"
                            name="dataSharingConsent"
                            onChange={() => setAgreedDataSharing(!agreedDataSharing)}
                            required
                        />
                        <label for="dataSharingConsent">
                            I consent to sharing my information with the selected entities as
                            indicated above.
                        </label>
                    </div>
                </div>
            {/* </div> */}

            <p>Emergency intake managed by Trusted Humanitarian Resources Int'l, Inc.</p>

            {/* Buttons */}
            <div className="button-row">
                <button onClick={prevStep}>Back</button>
                <button
                    onClick={handleSubmit}
                    disabled={!isFormValid || !agreedPrivacy || !agreedLegal || !agreedDataSharing}
                >
                    Submit
                </button>
            </div>
        </div>
    );
};

export default Review;
