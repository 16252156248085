import './App.css';
import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import Basics from './components/Basics';
import Location from './components/Location';
// import Incident from './components/Incident';
import Details from './components/Details';
import Review from './components/Review';
import Final from './components/Final';
import PageIndicator from './components/PageIndicator';

function App() {
  const [step, setStep] = useState(1);
  const [, setAuthenticated] = useState(false); // Track authentication status
  const [formData, setFormData] = useState({
    basics: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      affectedPeople: ''
    },
    location: {
      address: { street: '', city: '', state: '', zip: '', country: '' },
      coordinates: { lat: null, lng: null }
    },
    details: {
      incidentType: "",
      incidentDescription: "",
      images: [],
      imagePreviews: [], // To store image preview URLs
      proximityToHazard: "",
      communicationMethod: "",
      medicalSuppliesNeeded: false,
      petsNeedHelp: false,
      additionalRequests: ""
    }
  });

  const updateFormData = (newData, section) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [section]: {
        ...prevFormData[section],
        ...newData,
      },
    }));
  };

  const nextStep = () => {
    setStep(prevStep => prevStep + 1);
  };

  const prevStep = () => {
    setStep(prevStep => prevStep - 1);
  };

  return (
    <Router>
      <div className="App">
        <Routes>
          {/* Home Route */}
          <Route path="/" element={<Home setAuthenticated={setAuthenticated} />} />

          {/* Form Flow Route */}
          <Route path="/form" element={
            <>
              <PageIndicator
                currentStep={step}
                setStep={setStep}
                visible={step <= 4}
              />
              {step === 1 && <Basics nextStep={nextStep} updateFormData={updateFormData} formData={formData.basics} />}
              {step === 2 && <Location nextStep={nextStep} prevStep={prevStep} updateFormData={updateFormData} formData={formData.location} />}
              {/* {step === 3 && <Incident nextStep={nextStep} prevStep={prevStep} updateFormData={updateFormData} formData={formData.incident} />} */}
              {step === 3 && <Details nextStep={nextStep} prevStep={prevStep} updateFormData={updateFormData} formData={formData.details} />}
              {step === 4 && <Review nextStep={nextStep} prevStep={prevStep} formData={formData} updateFormData={updateFormData} />}
              {step > 4 && <Final />}
            </>
          } />
        </Routes>
      </div>
    </Router>
  );
}

export default App;